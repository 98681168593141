import ComboBox from "@/components/Elements/Autocomplete";
import ButtonForm from "@/components/Elements/Button/Form";
import axios from "axios";
import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

export default function SearchSizeForm({ tireWidth, mini = null, closeAllDialog = null }){
    const router = useRouter();
    const [loading, setLoading] = useState(false);
    const [showTireImage, setShowTireImage] = useState(false);
    const { register, handleSubmit, watch, control, reset, setValue, formState: { errors } } = useForm();
    const onSubmit = (data) => {
        setLoading(true);
        if(router.query.name){
            router.query.b = router.query.name.toUpperCase();
            delete router.query.name;
        }
        delete router.query.car_make;
        delete router.query.car_year;
        delete router.query.car_model;
        delete router.query.search;
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            event: 'search_now',
            search_by: 'tire_size',
            width: data.section_width,
            aspect_ratio: data.aspect_ratio,
            diameter: data.rim_size,
            make: "",
            model: "",
            year: "",
        });
        let q = {
            ...router.query,
            "section_width": data.section_width,
            "aspect_ratio": data.aspect_ratio,
            "rim_size": data.rim_size,
            "sn": "y",
            v: 1
        };
        if(mini){
            setLoading(false);
        }
        if(typeof closeAllDialog == "function"){
            q = {
                "section_width": data.section_width,
                "aspect_ratio": data.aspect_ratio,
                "rim_size": data.rim_size,
                "sn": "y",
                v: 1
            };
            setLoading(false);
            closeAllDialog();
            router.push({
                pathname: "/shop",
                query: q,
            });
        }
        else{
            router.push({
                pathname: "/shop",
                query: q,
            },
            undefined, { shallow: true});
        }

    };
    const onError = data => console.log(data);

    const [tireWidths, setTireWidths] = useState([]);
    const [selectedTireWidth, setSelectedTireWidth] = useState(null);

    const [tireArs, setTireArs] = useState([]);
    const [selectedTireAr, setSelectedTireAr] = useState(null);

    const [tireRims, setTireRims] = useState([]);
    const [selectedTireRim, setSelectedTireRim] = useState(null);

    const clearFilter = () =>{
        setSelectedTireWidth(null);
        setSelectedTireAr(null);
        setSelectedTireAr([]);
        setSelectedTireRim(null);
        setTireRims([]);
    };
    const handleChangeTireWidth = (width, init = null) => {
        setSelectedTireWidth(width);
        setSelectedTireAr(null);
        setSelectedTireAr([]);
        setSelectedTireRim(null);
        setTireRims([]);
        
        let arrArs = [];
        tireWidth?.find((tire, i) => {
            if(tire.section_width == width?.value){
                tire.aspect_ratio.map((ar, index) => {
                    arrArs.push({label: ar.aspect_ratio, value: ar.aspect_ratio});
                });
            }
        });
        let tires = tireWidth?.filter(w => w.section_width == width.value);
        if(tires){
            let rimSizes = [];
            tires[0].aspect_ratio.forEach(e => {
                e.rim_size?.forEach(rim => {
                    rimSizes.push({label: rim.rim_size, value: rim.rim_size});
                })
            });
            const uniqueRimSizes = rimSizes.filter((obj, index) => {
                return index === rimSizes.findIndex(o => obj.value === o.value);
            });
            setTireRims(uniqueRimSizes);
            if(init){
                if(router.query.rim_size){
                    setValue('rim_size', router.query.rim_size, { shouldDirty: true });
                    let rs = {label: router.query.rim_size, value: router.query.rim_size};
                    handleChangeTireRim(rs, 1);
                }
            }
        }
        setTireArs(arrArs);
        if(init){
            if(router.query.aspect_ratio){
                setValue('aspect_ratio', router.query.aspect_ratio, { shouldDirty: true });
                let ar = {label: router.query.aspect_ratio, value: router.query.aspect_ratio};
                handleChangeTireAr(ar, 1);
            }
        }
    };

    const handleChangeTireAr = async (ar, init = null) => {
        setSelectedTireAr(ar);
        setSelectedTireRim(null);
        setTireRims([]);
        // let url = `${process.env.NEXT_PUBLIC_API_URL}/product_list_dropdown_rim?vw=${selectedTireWidth?.value}&va=${ar.value}`;
        // await axios.get(url, {headers: {'Content-Type': 'application/json'}})
        //         .then(response => {
        //             if (response.status == 200) {
        //                 let arrRims = [];
        //                 response.data.map((r, inx) => {
        //                     arrRims.push({label: r.rim_size, value: r.rim_size});
        //                 });
        //                 setTireRims(arrRims);
        //             }else{
        //                 setTireRims([]);
        //             }
        //         })
        //         .catch(error => {
        //             console.log('error', error);
        //         });
        let arrRims = [];
        tireWidth?.find((tire, i) => {
            if(tire.section_width == selectedTireWidth?.value){
                tire.aspect_ratio.map((asr, inx) => {
                    if(asr.aspect_ratio == ar.value){
                        asr.rim_size.map((rim, index) => {
                            arrRims.push({label: rim.rim_size, value: rim.rim_size});
                        });
                    }
                });
            }
        });
        setTireRims(arrRims);
        if(init){
            if(router.query.rim_size){
                setValue('rim_size', router.query.rim_size, { shouldDirty: true });
                let rs = {label: router.query.rim_size, value: router.query.rim_size};
                handleChangeTireRim(rs, 1);
            }
        }
    };

    const handleChangeTireRim = (rim, init = null) => {
        setSelectedTireRim(rim);
    };

    useEffect(()=>{
        if(!router.isReady) return;
        if(router.query.section_width){
            setValue('section_width', router.query.section_width, { shouldDirty: true });
            let sw = {label: router.query.section_width, value: router.query.section_width};
            handleChangeTireWidth(sw, 1);
        }
        else{
            clearFilter();
            reset();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[router, tireWidth]);

    useEffect(()=>{
        if(!tireWidth) return;
        let arrTw = [];
        tireWidth.map((tire, index) => {
            arrTw.push({label: tire.section_width, value: tire.section_width});
        });
        //arrTw.sort((a,b) => a.label - b.label);
        setTireWidths(arrTw);
    },[tireWidth]);
    return(
        <div className={`w-full mx-auto rounded-md ${mini ? 'px-1' : 'px-4'}`}>
            <div className={`w-full transition-all duration-500 ease-in-out bg-no-repeat sm:bg-[length:672px_290px] bg-[length:352px_150px]  bg-top ${showTireImage ? 'bg-find-this sm:pt-64 pt-36' : 'pt-px'} rounded-lg`}>
                <form className={`${mini ? 'px-1 py-1' : 'px-4 py-2 rounded-b'}`} onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className={`grid ${mini ? 'grid-cols-1' : 'sm:grid-cols-3 grid-cols-1'} gap-2 mb-2`}>
                        <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { isTouched, isDirty, error },
                            }) => {
                                return(
                                    <Fragment>
                                        <ComboBox
                                            id="section_width"
                                            name="section_width"
                                            label="Width"
                                            error={errors.section_width != null}
                                            helperText={errors.section_width && errors.section_width?.message}
                                            onChange={(e, attr) => {
                                                if(attr){
                                                    onChange(attr.value);
                                                    handleChangeTireWidth(attr);
                                                }
                                            }}
                                            value={selectedTireWidth}
                                            options={tireWidths}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                        />
                                    </Fragment>
                                )
                            }}
                            name="section_width"
                            control={control}
                            rules={{ required: "Please select a tire width." }}
                        />
                        <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { isTouched, isDirty, error },
                            }) => {
                                return(
                                    <Fragment>
                                        <ComboBox
                                            id="aspect_ratio"
                                            name="aspect_ratio"
                                            label="Aspect Ratio"
                                            error={errors.aspect_ratio != null}
                                            helperText={errors.aspect_ratio && errors.aspect_ratio?.message}
                                            onChange={(e, attr) => {
                                                if(attr){
                                                    onChange(attr.value);
                                                    handleChangeTireAr(attr);
                                                }
                                            }}
                                            value={selectedTireAr}
                                            options={tireArs}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                        />
                                    </Fragment>
                                )
                            }}
                            name="aspect_ratio"
                            control={control}
                            rules={{ required: false }}
                        />
                        <Controller
                            render={({
                                field: { onChange, onBlur, value, name, ref },
                                fieldState: { isTouched, isDirty, error },
                            }) => {
                                return(
                                    <Fragment>
                                        <ComboBox
                                            id="rim_size"
                                            name="rim_size"
                                            label="Rim Diameter"
                                            error={errors.rim_size != null}
                                            loading={tireRims.length == 0}
                                            loadingText={'Checking available...'}
                                            helperText={errors.rim_size && errors.rim_size?.message}
                                            onChange={(e, attr) => {
                                                if(attr){
                                                    onChange(attr.value);
                                                    handleChangeTireRim(attr);
                                                }
                                            }}
                                            value={selectedTireRim}
                                            options={tireRims}
                                            isOptionEqualToValue={(option, value) => option.value === value.value}
                                        />
                                    </Fragment>
                                )
                            }}
                            name="rim_size"
                            control={control}
                            rules={{ required: "Please select a tire rim diameter." }}
                        />
                    </div>
                    <button
                        type="submit"
                        className="search-button"
                    >
                        Search for Tires
                    </button>
                    <div className="w-full mt-2">
                        <label className="text-blue-600 italic cursor-help font-semibold text-lg drop-shadow-xl" onClick={()=>setShowTireImage(!showTireImage)}>How to read tire size?</label>
                    </div>
                </form>
            </div>
        </div>
    );
}
